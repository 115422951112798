const isCloseOut = {
  data() {
    return {
      closeOutKey: 'closeout',
    };
  },
  computed: {
    isCloseOut() {
      return (
        !!this.$route.path.toLowerCase().match(this.closeOutKey.toLowerCase()) ||
        this.$route.query.closeout === 'yes' ||
        this.$route.query.closeout === 'true'
      );
    },
  },
  methods: {
    onRemoveCloseout() {
      if (!this.$refs.filters) return;
      if (this.$refs.filters.$refs.closeOut) this.$refs.filters.$refs.closeOut.onChange(false);
      if (this.$refs.filters.$refs.closeOut1) this.$refs.filters.$refs.closeOut1.onChange(false);
      this.$emit('onRemoveCloseout');
    },
    onRemoveNoMinimum() {
      if (!this.$refs.filters) return;
      if (this.$refs.filters.$refs.noMinimum) this.$refs.filters.$refs.noMinimum.onChange(false);
      if (this.$refs.filters.$refs.noMinimum1) this.$refs.filters.$refs.noMinimum1.onChange(false);
      this.$emit('onRemoveNoMinimum');
    },
  },
};

export { isCloseOut as default };
