
  import isCloseOut from '@/plugins/isCloseOut';
  export default {
    name: 'CLoseOutFilter',
    mixins: [isCloseOut],
    props: {
      count: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        _closeOut: false,
      };
    },
    watch: {
      isCloseOut(newVal, oldVal) {
        if (oldVal && !newVal) {
          this.$nextTick(() => (this.closeOut = newVal));
        }
      },
    },
    computed: {
      closeOut: {
        get() {
          return this.isCloseOut;
        },
        set(ev) {
          this.onChange(ev);
        },
      },
    },
    methods: {
      onChange(ev) {
        this.__closeOut = ev;
        const isCloseout = this.isCloseOut;
        let next = this.$route.path;
        const query = JSON.parse(JSON.stringify(this.$route.query));
        let refresh = query.closeout;
        if (this.$route.path.toLowerCase().match('/products/closeout')) {
          return this.$router.push({ path: '/' });
        } else if (ev && !isCloseout) {
          if (
            Object.keys(query).length ||
            this.$route.params.slug ||
            this.$route.path.toLowerCase().split('/products/search').length
          ) {
            query.closeout = 'yes';
            query.page = 1;
            refresh = true;
          } else {
            next += `/${this.closeOutKey}`;
            return this.$router.push({ path: next, query });
          }
        } else {
          query.page = 1;
          if (query.closeout) delete query.closeout;
        }
        if (!isCloseout || !ev) {
          next = next.replace('/closeout', '');
          if (next.split('/').length <= 2) next = '/';
        }

        this.$router.push({ path: next, query });
      },
    },
    created() {
      this._closeOut = this.isCloseOut;
    },
  };
